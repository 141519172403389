import { h, Component } from 'preact';
import { Router } from 'preact-router';

import Header from './header';
import Cars from '../routes/cars';
import Lcv from '../routes/lcv';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { filterAddedOptionals, prepareOptionals, createOptionalsHash, decodeOptionalsHash, parseEquipments } from '../UrcarUtils'
import Modal from './modal';

toast.configure({
	autoClose: 5000,
	draggable: false,
	//etc you get the idea
});

export default class App extends Component {
	state = {
		current:{
			brand: null,
			model: null,
			version: null
		},
		selected:{
			brand: null,
			model: null,
			version: null
		},
		inputs:{
			brand: "",
			model: "",
			version: ""
		},
		options:{
			brand: null,
			model: null,
			version: null
		},
		brands: [],
		models: [],
		versions: [],
		type: 'car',
		car: null,
		loading: {
			brand: false,
			model: false,
			version: false,
		},
		selectedEquip: [],
		equipstring: '',
		equiprstring: '',
		modalOpen: false,
		modalData: {}
	}

	async componentDidMount(){
		let urlpath = window.location.href.slice(window.location.href.indexOf("//")).split("/").slice(3);
		console.log("Version", window.location.hostname.indexOf("v1")===0? window.location.hostname.split(".")[0].replace(/\-/g,".") : "v1.1.2")
		console.log("loaded url", urlpath)
		let type = "car"
		if(urlpath[0] === "lcv") {
			type = "lcv";
		}
		this.setState({type});
		
		await this.loadBrands(type);
		if(urlpath[1]) {
			await this.loadModels(urlpath[1])
			if(urlpath[2]) {
				await this.loadVersions(urlpath[2])
				if(urlpath[3]) {
					await this.loadCar(urlpath[3])
					if(urlpath[4]) {
						const resout = decodeOptionalsHash(urlpath[4])
						this.setState(resout);
					} else {
						//console.error("no optionals")
					}
				} else {
					//console.error("no brand")
				}
			} else {
				//console.error("no model versions")
			}
		} else {
			//console.error("no brand models")
		}
	}

	handleRoute = e => {};

	updateInput = (name,value) => {
		this.setState(state => {
			state.inputs[name] = value;
			return state;
		})
	}

	createOptions = (type, data) => {
		const subs = {
			brand: {value: 'makeUrlCode', label: 'makeName'},
			model: {value: 'submodelCommercialUrlCode', label: 'submodelCommercialName'},
			version: {value: 'versionUrlCode', label: 'versionName'},
		}
		
		const options = data.map(item => {
			return {
				value: item[subs[type].value],
				label: item[subs[type].label] + (type==="version"? ` (${item.trimManufacturerCode})` : "")
			}
		});
		this.setState(state => {
			state.options[type] = options;
			return state;
		})
	}

	findOptional = (id) => {
		for(const z in this.state.car.equipments){
			const group = this.state.car.equipments[z];
			for(const y in group){
				const type = group[y];
				for(const equip of type){
					if(parseInt(equip.idEquipment) === parseInt(id)) {
						return equip;
					}
				}
			}
		}
		return null;
	}

	changeOptionals = (values) => {
		if(!this.state.car) return;
		const equips = [...this.state.selectedEquip];
		for(const value of values){
			console.log("checking",value)
			if(!this.state.selectedEquip.find(x => parseInt(x.idEquipment) === parseInt(value))){
				console.log("not found")
				const z = this.findOptional(value);
				if(z) {
					console.log("Pushing equip",z);
					equips.push(z);
				}
			} else {
				for(const x in equips){
					if(parseInt(equips[x].idEquipment) === parseInt(value)) equips.splice(x,1);
				}
			}
		}
		this.setState({
			selectedEquip: equips,
			equipstring: createOptionalsHash(equips),
			equiprstring: createOptionalsHash(equips,true)
		})
	}


	loadBrands = async(type) => {
		if(!type) type = this.state.type;
		try{
			console.log("loading brands")
			const data = await fetch(`https://api.urcar.it/v3/brands/${type}`);
			const brands = await data.json();
			this.setState({ brands });
			this.createOptions('brand', brands);
			console.log("done")
			return true;
		} catch(Err) {
			console.error(Err);
			return false;
		}
	}

	loadModels = async(brand) => {
		let models;
		try {
			const data = await fetch(`https://api.urcar.it/v3/${this.state.type}/${brand}/models`);
			models = await data.json();
			if(!models || models.error) {
				this.setState(state => { 
					state.loading.model = false;
					state.current.brand = null;
					state.selected.brand = null;
					return state;
				});
				return toast.error("Nessun modello disponibile");
			}
		} catch(err) {
			return toast.error("Nessun modello disponibile")
		}
		models = models.sort((i,j) => i.submodelCommercialName.localeCompare(j.submodelCommercialName));
		this.setState(state => { 
			state.models = models;
			state.current.brand = brand;
			state.selected.brand = brand;
			state.loading.model = false;
			return state;
		});
		this.createOptions('model', models);
	}

	loadVersions = async(model) => {
		let versions;
		const data = await fetch(`https://api.urcar.it/v3/${this.state.type}/${this.state.current.brand}/${model}/versions`);	
		versions = await data.json();
		console.log("data" , versions)
		if(versions){
			versions = versions.sort((i,j) => i.versionName.localeCompare(j.versionName));
			this.setState(state => { 
				state.versions = versions;
				state.current.model = model;
				state.selected.model = model;
				state.loading.version = false;
				return state;
			});
			this.createOptions('version', versions);
		}
	}
	
	loadCar = async(version) => {
		const data = await fetch(`https://api.urcar.it/v3/${this.state.type}/${this.state.current.brand}/${this.state.current.model}/${version}`);
		const car = await data.json();
		this.setState(state => { 
			state.current.version = version;
			state.selected.version = version;
			state.car = car;
			return state;
		});
	}

	changeBrand = async(e) => {
		console.log("changeBrand",e)
		const { value } = e.target;
		this.setState(state => {
			state.loading.model = true;
			state.current.model = null;
			state.selected.model = null;
			state.current.version = null;
			state.selected.version = null;
			state.models = [];
			state.versions = [];
			state.car = null;
			state.selectedEquip = [];
			return state;
		});
		if(!value || value === "") {
			this.setState(state => { 
				state.loading.model = false;
				state.current.brand = null;
				state.selected.brand = null;
				return state;
			});
			return;
		}
		this.loadModels(value);
	}

	changeModel = async(e) => {
		const { value } = e.target;
		console.log("changeModel",value)
		const brand = this.state.current.brand;
		this.setState(state => {
			state.loading.version = true;
			state.current.version = null;
			state.versions = [];
			state.car = null;
			state.selectedEquip = [];
			return state;
		});
		if(!value || value === "") {
			this.setState(state => { 
				state.current.model = null;
				state.selected.model = null;
				state.loading.version = false;
				return state;
			});
			return;
		}

		this.loadVersions(value);
	}

	changeVersion = async(e) => {
		const { value } = e.target;
		if(!value || value === "") {
			this.setState(state => { 
				state.current.version = null;
				state.selected.version = null;
				state.car = null;
				state.selectedEquip = [];
				return state;
			});
			return;
		}
		this.loadCar(value);
	}

	openModal = () => this.setState({modalOpen:true});

	addOptional = async (optional) => {
		const currentConfig = this.state.selectedEquip.map(i => i.idEquipment || i.id);
		const data = await fetch(`https://api.urcar.it/v3/${this.state.type}/addEquipments`,{
			method: 'POST',
			headers: {
			  'Accept': 'application/json',
			  'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				versionId: this.state.car.versionId, 
				addConfig: optional,
				currentConfig: currentConfig.join(",")
			})
		});

		const result = await data.json();
		console.log("AddOptional result", result);

		if(result.status === "OK") {
			if(result.equipmentDTOs.length) {
				const allids = result.equipmentDTOs.map(i => parseInt(i.idEquipment || i.id))
				const selectedEquip = filterAddedOptionals(allids, this.state.car)// optionals.filter(i => allids.includes(i.idEquipment) )
				this.setState({ selectedEquip, equipstring: createOptionalsHash(selectedEquip) });
				const text = selectedEquip.filter(i => !currentConfig.includes(i.idEquipment)).map(i => i.description).join(", ")
				toast.success(`Aggiunto: ${text}`, {})
			}
		} else {
			if(result.alternatives){
				let toOpen = false;
				if(result.alternatives && result.alternatives.decision){
					const modalData = parseEquipments(result,optional);
					if(modalData) {
						toOpen = true;
						this.setState({modalData})
					}
				}
				if(toOpen) this.openModal();
			}
		}
	}
	
	removeOptional = async (optional) => {
		const data = await fetch(`https://api.urcar.it/v3/${this.state.type}/removeEquipments`,{
			method: 'POST',
			headers: {
			  'Accept': 'application/json',
			  'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				versionId: this.state.car.versionId, 
				removeConfig: optional,
				currentConfig: this.state.selectedEquip.map(i => i.idEquipment || i.id).join(",")
			})
		});
		const result = await data.json();
		if(result.status === "OK") {
			this.changeOptionals([optional])
		}
	}

	clickOptional = (e) => {
		const { name, value } = e.target;
		const exists = this.state.selectedEquip.find(x => parseInt(x.idEquipment) === parseInt(value));
		if(!exists){
			this.addOptional(value);
		} else {
			this.removeOptional(value);
		}
	}

	acceptSingleOptionalChange = (e) => {
		const { id } = e.target;
        switch(this.state.modalData.dec) {
            case "ADD_ONE_OF":
                const itemToAdd = this.state.modalData.itemlist.find(i => i.idEquipment === parseInt(id));
                if(!itemToAdd) break;
                //this.changeOptionals([]);
                toast.success(`Aggiunti: ${itemToAdd.description}, ${this.state.modalData.request.description}`,{bodyClassName: 'toast-style', position: toast.POSITION.TOP_CENTER});
                break;
            case "REMOVE_ONE_OF":
                
                break;
            default: break;
        }
        this.setState({modalOptionals: false});
	}

	acceptAllOptionalChange = (e) => {
		console.log(this.state.modalData.itemlist)
		const allids = this.state.modalData.itemlist.map(i => i.idEquipment || i.id);
		allids.push(parseInt(this.state.modalData.request));
		const selectedEquip = this.filterAddedOptionals(allids);
		this.setState({ selectedEquip, equipstring: createOptionalsHash(selectedEquip) });
		const currentConfig = this.state.selectedEquip.map(i => i.idEquipment || i.id);
		const text = selectedEquip.filter(i => !currentConfig.includes(i.idEquipment)).map(i => i.description).join(", ")
		toast.success(`Aggiunto: ${text}`, {})
		this.closeModal()
	}

	closeModal = (e) => {
		this.setState({modalOpen: false })
	}

	saveData = async () => {
		console.log('car',this.state.car)
		const dataout = this.state.car;
		if(dataout) {
			const url =`${this.state.current.brand}/${this.state.current.model}/${this.state.current.version}`;
			dataout.vehicleType = this.state.type;
			dataout.vehicleUrl = url;
			//dataout.optionString = this.state.equipstring;

			/* const data = await fetch(`https://offers-cache.urcar.it/save`,{
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Authorization': 'N2YzZDIxMDQ3MTc2ZjQ4Mjg2NmVkNjIyODRkYTZmYzdjOTFjMzkwZGI5Y2FiYzE5NDU0MmQzMGEyMjA0NmE5ZSAgLQo='
				},
				body: JSON.stringify({data: dataout, options: this.state.equipstring})
			}); */

			//const url =`${this.state.current.brand}/${this.state.current.model}/${this.state.current.version}/${this.state.equipstring}`;
			dataout.optionalsSelected = this.state.selectedEquip;
			if(window.parent) {
				window.parent.postMessage(JSON.stringify({data: dataout, options: this.state.equipstring, hash: true}), "*");
			}
		}
	}
	
	render() {
		
		return (
			<div id="app">
				<Header />
				<Modal modalOpen={this.state.modalOpen} 
					data={this.state.modalData} 
					click={this.acceptSingleOptionalChange}  
					acceptAllOptionalChange={this.acceptAllOptionalChange}  
					closeModal={this.closeModal}
				/>
				
				<Router onChange={this.handleRoute}>
					<Cars path="/car/:bb?/:mm?/:vv?/:opts?" 
						data={this.state} 
						changeBrand={this.changeBrand} 
						changeModel={this.changeModel} 
						changeVersion={this.changeVersion} 
						changeOptional={this.clickOptional}
						optionalsData={prepareOptionals(this.state.car)}
						loading={this.state.loading}
						updateInput={this.updateInput}
					/>
					<Lcv path="/lcv/:bb?/:mm?/:vv?/:opts?" data={this.state}
						changeBrand={this.changeBrand} 
						changeModel={this.changeModel} 
						changeVersion={this.changeVersion} 
						changeOptional={this.clickOptional}
						optionalsData={prepareOptionals(this.state.car)}
						loading={this.state.loading}
						updateInput={this.updateInput}
					/>
				</Router>
				<div style={{width: '100%', position: 'fixed', bottom: 0, height: 50, padding: 10}}>
					{this.state.car ? <button 
						style={{width: '100%', height: 40, fontWeight: 700, color: 'white', background: 'var(--secondary-color)'}}
						onClick={this.saveData}
					>Seleziona</button> : <div style={{height: 70}}></div>}
				</div>
			</div>
		);
	}
}
