import { h } from 'preact';
import { Link } from 'preact-router/match';
import style from './style.css';

const Header = () => (
	<header class={style.header}>
		<h1><img style={style.header} src="https://urcar.it/wp-content/uploads/2019/07/logo-neg-urcar.png" /></h1>
		<div class={style.buildName}>v1.1.3</div>
	</header>
);

export default Header;
